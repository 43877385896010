import React from 'react'
import Typeds from 'typed.js';

function Typed(props) {
    const el = React.useRef(null);

    console.log(props.strings1, props.strings2)

    React.useEffect(() => {
        const typed = new Typeds(el.current, {
            strings: props.s,
            typeSpeed: 100,
            loop: true,
            backDelay: 1000,
            fadeOutDelay: 1000,
        });

        return () => {
            typed.destroy();
        };
    }, []);
    return (
        <span ref={el} />
    )
}

export default Typed