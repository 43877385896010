import React from "react";
import Heading from "../Custom/Heading";
import { BsInstagram, BsLinkedin, BsGithub } from "react-icons/bs";

function MeetMe() {
  return (
    <div className="py-10">
      <Heading title="Meet Me" />
      <div className="flex items-center justify-center">
        <div
          className="social-button text-2xl py-5 cursor-pointer"
          onClick={() => window.open("https://www.instagram.com/bazzree/")}
        >
          <BsInstagram />
          <h4>@bazzree</h4>
        </div>
        <div
          className="social-button text-2xl py-5 cursor-pointer"
          onClick={() =>
            window.open("https://www.linkedin.com/in/akmal-maulana-33357b221/")
          }
        >
          <BsLinkedin />
          <h4>Akmal Maulana Basri</h4>
        </div>
        <div
          className="social-button text-2xl py-5 cursor-pointer"
          onClick={() => window.open("https://github.com/akmalmaulanabasri")}
        >
          <BsGithub />
          <h4>@akmalmaulanabasri</h4>
        </div>
      </div>
    </div>
  );
}

export default MeetMe;
