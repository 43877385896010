import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
import Experience from "../Components/Experience";
import Projects from "../Components/Projects";
import MeetMe from "../Components/MeetMe";

const Home = () => {
  return (
    <div className="bg-[#0f172a] lg:px-10">
      <div className="container px-2">
        <Navbar />
        <Hero />
        <Experience />
        {/* <Projects limit="2" /> */}
        <MeetMe />
      </div>
    </div>
  );
};

export default Home;
