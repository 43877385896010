import React from 'react'
import Typed from '../Custom/Typed'
import withReactContent from 'sweetalert2-react-content'
import me from '../../assets/img/me.png'
import Swal from 'sweetalert2'

function Hero() {
    const MySwal = withReactContent(Swal)

    return (
        <div className="container" id="hero">
            <div className="wrapper">
                <div className="wrapper-left">
                    {/* <h1><Typed s={["i'am Software Engineer", "Hi, i'am Akmal Maulana"]} /></h1> */}
                    <h1>Hi, i'am Akmal Maulana</h1>
                    <h4>Students of Wikrama Vocational School.</h4>
                    <div className="flex">
                        <a href="#project" className="mx-2 btn">My Project</a>
                        <a href="#" className="mx-2 btn border-dashed" onClick={() => MySwal.fire(
                            'Coming Soon :)',
                            '',
                            'question'
                        )}>Check my CV</a>
                    </div>
                </div>
                <div className="wrapper-right mt-5">
                    <img src={me} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Hero