import React from "react";
import Aos from "aos";
import "../../assets/aos.css";
export default function Modal(props) {
  Aos.init();
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 bg-[#000000c7]"></div>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50"
        data-aos="fade-down"
      >
        <div className="relative my-6 mx-auto max-w-3xl w-[80%] md:w-auto">
          <div className="rounded relative flex flex-col w-full bg-[#5ce5d4]">
            <div className="flex items-center justify-center p-3">
              <h3 className="sm:text-sm md:text-xl text-center font-semibold text-[#0f172a]">
                {props.info}
              </h3>
            </div>
            <div className="">
              <img className="h-auto object-cover" src={props.img} alt="" />
            </div>
            <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
              <button
                className="bg-red-500 text-white rounded-sm font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.show(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
