import React from "react";
import Heading from "../Custom/Heading";
import Modal from "./Modal";
import Aos from "aos";
import "../../assets/aos.css";
import pingfest from "../../assets/img/cert/pingfest.jpg";
import linux from "../../assets/img/cert/linux.jpg";
import docker from "../../assets/img/cert/docker.jpg";
import cyber from "../../assets/img/cert/cyber.jpg";
import dibo from "../../assets/img/cert/dibo.jpg";
import data from "../../assets/img/cert/data.jpg";
import htmlcss from "../../assets/img/cert/htmlcss.jpg";
import ispo from "../../assets/img/cert/ispo.jpg";

function Experience() {
  Aos.init();
  const [showModal, setShowModal] = React.useState(null);
  const [imgModal, setImgModal] = React.useState(null);

  const experience = [
    { title: "HTML CSS INTERNATIONAL CERTIFICATION", img: htmlcss },
    { title: "PINFEST BATTLE OF TECHNOLOGY", img: pingfest },
    { title: "SMK DIGITAL BOOTCAMP", img: dibo },
    { title: "COMPTIA CYBERSECURITY TRAINING", img: cyber },
    { title: "LINUX FUNDAMENTAL", img: linux },
    { title: "MASTERING DOCKER", img: docker },
    { title: "DATA ENGINEERING", img: data },
    { title: "INDONESIA SCIENCE PROJECT OLYMPIAD", img: ispo },
  ];

  const modalStatus = (val) => {
    setShowModal(val);
  };

  function setModal(title, img) {
    setShowModal(title);
    setImgModal(img);
  }

  return (
    <div className="" id="experience">
      <Heading title="Experience" />
      <div className="cards py-5">
        {experience.map((exp, index) => (
          <div
            className="card"
            key={index}
            onClick={() => setModal(exp.title, exp.img)}
          >
            <h2>{exp.title}</h2>
          </div>
        ))}
      </div>
      {showModal && (
        <>
          <Modal show={modalStatus} info={showModal} img={imgModal} />
        </>
      )}
    </div>
  );
}

export default Experience;
