import React from 'react'
import { BsX, BsList } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Navbar = () => {
  const navs = ['Home', 'About', 'Contact', 'Services']
  const [navbar, setNavbar] = React.useState(false)
  const navigate = useNavigate();

  function navClick(nav) {
    navigate(nav)
    setNavbar(false)
  }

  const MySwal = withReactContent(Swal)

  function comingSoon(){
    MySwal.fire(
      'Coming Soon :)',
      '',
      'question'
    )
  }

  return (
    <div className='navbar'>
      <h1 className='NavMaster'>Akmal Maulana</h1>
      <ul className="navbars nmobile">
        {navs.map((nav, index) => (
          <li key={index}>
            <a onClick={
              () => 
              // navClick(nav)
              comingSoon()
            }>{nav}</a>
          </li>
        ))}
      </ul>
      <ul className={!navbar ? 'navbars mobile hidden left-0' : 'navbars mobile flex right-0'} >
        {navs.map((nav, index) => (
          <li key={index} onClick={
            () => 
            // navClick(nav)
            comingSoon()
          }>
            <a >{nav}</a>
          </li>
        ))}
      </ul>
      <div className="burger-icons" onClick={() => setNavbar(!navbar)}>
        {!navbar ? <BsList className='icon' /> : <BsX className='icon' />}
      </div>
    </div>
  )
}

export default Navbar